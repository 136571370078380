<template>
  <div>
    <apexchart v-if="chartData.length > 0" :series="chartData" type="bar" height="350" :options="chartOptions"/>
  </div>
</template>

<script>
export default {
  name: "BudgetCategoryChart",
  methods: {
    handleSelection(event, chartContext, config) {
      let selectCategory = this.xCategories[config.dataPointIndex]
      this.$emit('select', {category: selectCategory})
    }
  },
  props: {
    chartData: {
      type: Array,
      default: function() {return [{}]},
    },
    xCategories: {
      type: Array,
      default: function() {return [{}]},
    },
    horizontal: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: '',
    }
  },
  computed: {
    chartOptions() {
      return {
        chart: {
          zoom: {
            enabled: true,
          },
          type: 'bar',
          height: 350,
          events: {
            dataPointSelection: this.handleSelection,
          },
        },
        plotOptions: {
          bar: {
            horizontal: this.horizontal,
            columnWidth: '55%',
            endingShape: 'rounded',
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          tickPlacement: 'on',
          // categories: this.xCategories,
          title: {
            text: '$'
          }
        },
        fill: {
          opacity: 1,
        },
        title: {
          text: this.title,
          align: 'center',
          style: {
            fontSize: '25px',
          }
        },
        tooltip: {
          // custom: function({series, seriesIndex, dataPointIndex, w}) {
          //   console.log(w)
          //   return '<div class="arrow_box">' +
          //       '<span>' + series[seriesIndex][dataPointIndex] + '</span>' +
          //       '</div>'
          // },
          y: {
            formatter: function(val) {
              return '$ ' + val
            }
          },
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
