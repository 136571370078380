<template>
  <div>
    <apexchart
        v-if="prepData.numRows() > 0"
        type="bar"
        height="350"
        :options="overviewChartOptions"
        :series="overviewChartSeries"
    />
    <v-progress-linear indeterminate v-if="monthDataRunning" />
    <budget-category-chart
        :chart-data="budgetCategoryData"
        :x-categories="budgetCategoryNames"
        :title="selectedMonth"
        @select="selectCategory"
    />
    <v-row justify="center" v-if="budgetCategoryData.length > 0">
      <v-btn-toggle v-model="drillType" :mandatory="true">
        <v-btn>Drill Down</v-btn>
        <v-btn>Drill Across</v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row v-if="displayDrillAcross">
      <!-- drill across -->
      <budget-category-chart
          v-if="drillType === 1"
          :chart-data="acrossData"
          :x-categories="acrossKeys"
          :horizontal="false"
          :title="'Drill Across Category: ' + selectedCategory"
          style="width:100%"
          @select="selectDrillAcross"
      />
    </v-row>
    <v-progress-linear indeterminate v-if="budgetCategoryDataRunning" />
    <v-row v-if="displayDrillDown">
      <v-col cols="12">
        <v-card>
          <v-card-title style="justify-content: center">
            {{ this.drillMonth }} - {{ this.selectedCategory }}
          </v-card-title>
          <v-card-text>
            <!-- drill down OR after drill across-->
            <v-data-table
                :headers="drillDownHeaders"
                :items="drillDownDisplayData"
                item-key="budgettransactid"
                class="elevation-1"
                :search="transactSearch"
                v-on:click:row="selectTransactRow"
            >
              <template v-slot:top>
                <v-row>
                  <v-spacer/>
                  <v-text-field
                    label="Search"
                    v-model="transactSearch"
                    prepend-inner-icon="mdi-magnitfy"
                    style="max-width:300px"
                  />
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
        v-model="editTransactDialog"
        max-width="800px" persistent
        v-if="activeTransact && activeTransact.transactid"
    >
      <transact-form
          :instance-id="drillInstanceId"
          :input-type="activeTransact.transactType"
          :id="activeTransact.transactid.toString()"
          :name="activeTransact.name"
          :description="activeTransact.description"
          :date="activeTransact.date"
          :datePost="activeTransact.datePost"
          :amount="activeTransact.amount"
          :tags="activeTransact.tags"
          :to-account="activeTransact.toAccount"
          :from-account="activeTransact.fromAccount"
          :transact-attr-link-id="activeTransact.primarytransactattrlink[0].transactattrlinkid.toString()"
          :budgets="activeTransact.budgets"
          :key="activeTransact.transactid"
          v-on:cancel="editTransactDialog = false"
          v-on:save="saveTransact"
          v-on:delete="deleteTransact"
        />

    </v-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import {Action, Getter} from '@/store/summary/types'
import {Getter as ProfileGetter} from '@/store/profile/types'
import {Getter as TransactGetter, Action as TransactAction} from '@/store/transact/types'
import {Action as BudgetAction} from "@/store/budgetTransact/types";
import * as aq from 'arquero'
import BudgetCategoryChart from "@/components/summary/BudgetCategoryChart";
import TransactForm from "@/components/TransactForm";

export default {
  name: "Summary",
  components: {TransactForm, BudgetCategoryChart},
  methods: {
    selectTransactRow: function(arg1, arg2) {
      this.editTransactDialog = true
      this.$store.dispatch(`${[TransactAction.ActivateTransact]}`, {transactid: arg1.transactid})
      console.log(arg1)
      console.log(arg2)
    },
    saveTransact(transact){
      // TODO - would be great to check for duplicates here...
      console.log(transact)
      this.$store.dispatch(`${[TransactAction.UpsertTransact]}`, transact).then(
          () => {
            // refresh / update the list of budget transactions!
            this.$store.dispatch(
                `${[BudgetAction.BudgetTransactList]}`,
                {instanceid: this.activeInstance.instanceid}
            )
          })
      // TODO - catch errors!
      this.editTransactDialog = false
    },
    deleteTransact: function(transactid) {
      console.log(transactid)
      this.$store.dispatch(`${[TransactAction.DeleteTransact]}`, {transactid: transactid}).then(
          () => {
            // refresh / update the list of budget transactions!
            this.$store.dispatch(
                `${[BudgetAction.BudgetTransactList]}`,
                {instanceid: this.activeInstance.instanceid}
            )
          }
      )
      // TODO - catch errors!
      this.editTransactDialog = false
    },
    handleSelection(event, chartContext, config) {
      let selectMonth = this.prepData.get('month', config.dataPointIndex)
      let selectSeries = config.seriesIndex

      this.displayDrillAcross = false
      this.displayDrillDown = false

      let typeid
      if (selectSeries === 0) {
        // budget
        typeid=1
      } else {
        // savings / global
        typeid=2
      }
      this.setBudgetCategories(selectMonth, typeid)
    },
    setBudgetCategories(month, typeid=1) {
      let dat = aq.from(this.monthData)
      let filtered = dat
        .filter(aq.escape(d => d.month === month))
        .filter(aq.escape(d => d.budgetcategory.budget.budgettypeid === typeid))
        .derive({category: aq.escape(d => d.budgetcategory.name)})
        .groupby(['month', 'category'])
        .rollup({
          actual: d => aq.op.sum(d.actual),
          amount: d => aq.op.max(d.amount),
        })
        .derive({
          actual: aq.escape(d => d.actual.toFixed(2)),
          goals: aq.escape(d => {if (d.amount) {return [{name: 'Target', value: d.amount, strokeWidth: 5, strokeColor: "#775DD0"}]} else {return []}}),
        })
        .derive({
          series: aq.escape(d => {return {'y': d.actual, 'goals': d.goals, 'x': d.category}}),
        })
        .orderby('category')

      console.log(filtered)
      Vue.set(this, 'budgetCategoryData', [
        {
          name: 'Budget Categories',
          data: filtered.array('series')
        }
      ])

      Vue.set(this, 'budgetCategoryNames', filtered.array('category'))
      Vue.set(this, 'selectedMonth', month)
    },
    getDrillAcrossData(month, category) {
      if (!this.monthData || this.monthData.numRows() === 0) {
        return this.monthData
      }

      let budgetCategoryId = this.getCategoryID(month, category)
      if (budgetCategoryId === 0) {
        return this.monthData
      }
      // TODO - more!!
      let acrossData = aq.from(this.monthData)
          .filter(aq.escape(d => d.budgetcategoryid === budgetCategoryId))
          .derive({category: aq.escape(d => d.budgetcategory.name)})
          .groupby(['month', 'category'])
          .rollup({
            actual: d => aq.op.sum(d.actual)
          })
          .derive({
            actual: aq.escape(d => d.actual.toFixed(2))
          })
          .derive({
            series: aq.escape(d => {return {'y': d.actual, 'x': d.month}}),
          })
          .orderby('month')

      this.displayDrillAcross = true

      Vue.set(this, 'acrossData', [
        {
          name: 'Category Over Time',
          data: acrossData.array('series')
        }
      ])
      Vue.set(this, 'acrossKeys', acrossData.array('month'))

    },
    getDrillDownData(month, category) {
      if (!this.monthData || this.monthData.numRows() === 0) {
        return this.monthData
      }
      let categoryId = this.getCategoryID(month, category)
      if (categoryId === 0) {
        return this.monthData
      }
      let instanceId = this.getInstanceID(month, category)
      if (instanceId !== 0) {
        Vue.set(this, 'drillInstanceId', instanceId)
      }

      this.drillMonth = month
      this.displayDrillDown = true

      this.$store.dispatch(`${[Action.FetchBudgetCategoryData]}`, {budgetcategoryid: categoryId, month: month})
      // TODO - more!!
    },
    getInstanceID(month, category) {
      if (!this.monthData || this.monthData.numRows() === 0) {
        return 0
      }
      let dat = aq.from(this.monthData)
          .filter(aq.escape(d => d.month === month))
          .filter(aq.escape(d => d.budgetcategory.name === category))
          .get('instanceid')
      console.log(dat)
      if (dat.length > 1) {
        // if an array, get first entry
        dat = dat[0]
      }
      return dat
    },
    getCategoryID(month, category) {
      if (!this.monthData || this.monthData.numRows() === 0) {
        return 0
      }
      let dat = aq.from(this.monthData)
          .filter(aq.escape(d => d.month === month))
          .filter(aq.escape(d => d.budgetcategory.name === category))
          .get('budgetcategoryid')
      console.log(dat)
      if (dat.length > 1) {
        // if an array, get first entry
        dat = dat[0]
      }
      return dat
    },
    selectDrillAcross(event) {
      console.log(event)
      // TODO: this is called a 'category' :( even though it is a month...
      this.getDrillDownData(event.category, this.selectedCategory)
    },
    selectCategory(event) {
      console.log(event)
      Vue.set(this, 'selectedCategory', event.category)
      if (this.drillType === 1) {
        // drill across
        this.displayDrillDown = false
        this.getDrillAcrossData(this.selectedMonth, this.selectedCategory)
      } else {
        // drill down
        this.getDrillDownData(this.selectedMonth, this.selectedCategory)
      }
    },
    setOptions(dat, callback) {
      let opts = {
        chart: {
          zoom: {
            enabled: true,
            type: 'x',
          },
          type: 'bar',
          height: 350,
          events: {
            dataPointSelection: callback,
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '95%',
            endingShape: 'rounded',
          }
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          tickPlacement: 'on',
          categories: dat.array('month'),
        },
        yaxis: {
          title: {
            text: '$'
          }
        },
        fill: {
          opacity: 1,
        },
        title: {
          text: "Overview by Month",
          align: 'center',
          style: {
            fontSize: '25px',
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return '$ ' + val
            }
          }
        }
      }
      return opts
    },
  },
  data() {
    return {
      selectedMonth: null,
      selectedCategory: null,
      budgetCategoryData: [],
      budgetCategoryNames: [],
      acrossData: [],
      acrossKeys: [],
      switchVal: true,
      drillType: 0,
      drillMonth: null,
      overviewChartSeries: [],
      overviewChartOptions: {},
      transactSearch: null,
      displayDrillDown: false,
      displayDrillAcross: false,
      editTransactDialog: false,
      drillInstanceId: 0,
      drillDownHeaders: [
        { text: 'Date', value: 'transact.transactdate'},
        { text: 'Name', value: 'transact.name'},
        { text: 'Budget Description', value: 'description'},
        { text: 'Description', value: 'transact.description'},
        { text: 'Amount', value: 'amount'},
      ],
    }
  },
  computed: {
    activeTransact() {
      return this.$store.getters[`${[TransactGetter.GetActiveTransact]}`]
    },
    budgetCategoryDataRunning() {
      return this.$store.getters[`${[Getter.GetBudgetCategoryDataRunning]}`]
    },
    activeFinpic() {
      return this.$store.getters[`${[ProfileGetter.getActiveFinpic]}`]
    },
    drillData() {
      // TODO: compute from a getter w/ transact info
      let dat = aq.from(this.$store.getters[`${[Getter.GetBudgetCategoryData]}`])

      dat = dat.filter(aq.escape(d => d.finpicid === this.activeFinpic.finpicid))
      return dat
    },
    drillDownDisplayData() {
      return this.drillData.objects()
    },
    monthDataRunning() {
      return this.$store.getters[`${[Getter.GetMonthSummaryRunning]}`]
    },
    monthData() {
      const findYearRegex = /Year/
      let dat = aq
          .from(this.$store.getters[`${[Getter.GetMonthSummary]}`])
          .derive({
            month: aq.escape(d => {if (d.name === "Global" || d.name.match(findYearRegex)) {return d.month} else {return d.effective}})
          })
      if (dat.numRows() > 0) {
        return dat
      } else {
        return aq.from([])
      }
    },
    prepData() {
      if (!this.monthData || this.monthData.numRows() === 0) {
        return this.monthData
      }
      console.log(this.activeFinpic)
      let dat = aq.from(this.monthData)
          .filter(aq.escape(d => d.finpicid = this.activeFinpic.finpicid))
          .derive({
            actual: aq.escape(d => {if (d.budgetcategory.budget.budgettypeid === 1) {return d.actual} else {return 0}}),
            actual_neg: aq.escape(d => {if (!d.budgetcategory.income && d.budgetcategory.budget.budgettypeid === 1) {return d.actual} else {return 0}}),
            actual_pos: aq.escape(d => {if (d.budgetcategory.income && d.budgetcategory.budget.budgettypeid === 1) {return d.actual} else {return 0}}),
            global: aq.escape(d => {if (d.budgetcategory.budget.budgettypeid === 2) {return d.actual} else {return 0}})
          })
          .groupby('month')
          // .filter(d => aq.op.equal(d.budgetcategory.income, false))
          .rollup(
              {
                actual: d => aq.op.sum(d.actual),
                actual_neg: d => aq.op.sum(d.actual_neg),
                actual_pos: d => aq.op.sum(d.actual_pos),
                global: d => aq.op.sum(d.global),
              }
              )
          .derive({
            actual: aq.escape(d => { return d.actual.toFixed(2) }),
            actual_neg: aq.escape(d => { return d.actual_neg.toFixed(2) }),
            actual_pos: aq.escape(d => {return d.actual_pos.toFixed(2) }),
            global: aq.escape(d => {return d.global.toFixed(2) }),
          })
          .orderby('month')
      return dat
    },
  },
  mounted() {
    this.$store.dispatch(`${[Action.FetchMonthSummary]}`)
  },
  watch: {
    drillType(curr, prev) {
      if (prev !== curr) {
        this.displayDrillDown = false
        this.displayDrillAcross = false
      }
    },
    prepData(curr) {
      if (!curr || curr.numRows() === 0) {
        Vue.set(this, 'overviewChartSeries', [])
        let opts = this.setOptions(curr, this.handleSelection)
        Vue.set(this, 'overviewChartOptions', opts)
      }
      let dat = [
        {
          name: 'Budget',
          data: curr.array('actual')
        },
        {
          name: 'Global',
          data: curr.array('global')
        }
      ]

      let opts = this.setOptions(curr, this.handleSelection)

      Vue.set(this, 'overviewChartSeries', dat)
      Vue.set(this, 'overviewChartOptions', opts)
    }
  },
}
</script>

<style scoped>

</style>
